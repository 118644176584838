/* External dependencies */
import { Anchor, Checkbox, Col, Label, Row, Text } from 'atomize';
import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import ClipIcon from '../../assets/svg/clip';
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import { Vehicle, VehicleStatus } from '../create-vehicle/vehicles_types';
import FormField from '../form/FormField';
import { rowVehicleStyle } from '../form/FormStyles';
import { validateDeclarationNumber } from '../invoices/invoice/redux/validation';
import ConsigneePassToPrint from '../print/ConsigneePassToPrint';
import IconButtonPrint from '../print/IconButtonPrint';
import Spinner from '../spinner/Spinner';
import { CargoState } from './redux/reducer';

export default function CargoDetail() {
  const colSize = '7';
  const borderColor = 'search_bg_color';

  const { cargo, loading, error } = ReactRedux.useSelector(
    (state: RootState): CargoState => state.cargo,
  );
  const { trailerPlateNumber, status } = ReactRedux.useSelector(
    (state: RootState): Vehicle => state.vehicle.vehicle,
  );

  const addConsignee = formatMessage('addConsignee');
  const consignee = formatMessage('Consignee');
  const cargoWeightLabel = formatMessage('cargoWeight');
  const departureCountryLabel = formatMessage('departureCountry');
  const enterCargoWeight = formatMessage('enterCargoWeight');
  const enterSeats = formatMessage('enterSeats');
  const escort = formatMessage('escort');
  const enterWeight = formatMessage('enterWeight');
  const numberOfSeats = formatMessage('numberOfSeats');
  const productsLabel = formatMessage('products');
  const totalCargoWeightNet = formatMessage('totalCargoWeightNet');
  const totalNumberOfSeats = formatMessage('totalNumberOfSeats');

  const totalSum = cargo?.consignees?.reduce(
    (acc, item) => acc + (item?.invoice?.paymentAmount || 0),
    0,
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <FormattedMessage id={error.code} />;
  }

  return (
    <form>
      <Row p={{ l: '1.5rem', r: { xs: '1.5rem', lg: '0' } }}>
        <Col size={{ xs: '12', lg: '6' }}>
          <Row {...rowVehicleStyle} align="baseline" p={{ t: '1rem' }}>
            <FormField
              bg="input_filled_bg"
              colSize={colSize}
              label={totalCargoWeightNet}
              inputValue={cargo.cargoNetWeight}
              inputType="text"
              inputName="cargoNetWeight"
              placeholder={enterCargoWeight}
              required={true}
              tabIndex="1"
              disabled
            />
          </Row>
          <Row {...rowVehicleStyle} align="baseline" p={{ t: '1rem' }}>
            <FormField
              bg="input_filled_bg"
              colSize={colSize}
              label={totalNumberOfSeats}
              inputValue={cargo.cargoSlots}
              inputType="text"
              inputName="cargoSlots"
              placeholder={enterSeats}
              required={true}
              tabIndex="1"
              disabled
            />
          </Row>
          <Row
            {...rowVehicleStyle}
            align="baseline"
            border={{ b: '1px solid' }}
            borderColor={borderColor}
            p={{ t: '1rem' }}
          >
            <FormField
              bg="input_filled_bg"
              colSize={colSize}
              label={escort}
              inputValue={cargo.cargoEscort}
              inputType="text"
              inputName="cargoEscort"
              required={true}
              tabIndex="1"
              disabled
            />
          </Row>
        </Col>
      </Row>

      <Row p={{ l: '1.5rem' }}>
        <Col>
          <Text tag="h2" textSize="subheader" m={{ y: '20px' }}>
            <FormattedMessage id="Consignees" />
          </Text>
        </Col>
      </Row>
      {cargo.consignees.map(
        (
          {
            name,
            slots,
            cargoWeight,
            transit,
            departureCountry,
            products,
            vehiclePlateNumber,
            arrivalDate,
            invoice,
          },
          index,
        ) => {
          const departureCountryValue = formatMessage(departureCountry);

          return (
            <Row
              {...rowVehicleStyle}
              align="center"
              p={{ t: '1rem', l: '1.5rem', r: { xs: '1.5rem', lg: '0' } }}
              key={index}
            >
              <Col size={{ xs: '12', lg: '6' }}>
                <Row {...rowVehicleStyle} maxW="600px">
                  <FormField
                    bg="input_filled_bg"
                    colSize="12"
                    label={consignee}
                    inputValue={name}
                    inputType="text"
                    inputName="name"
                    placeholder={addConsignee}
                    required={true}
                    tabIndex="1"
                    disabled
                  />
                </Row>
              </Col>
              <Col size={{ xs: '6', sm: '4', lg: '2' }}>
                <Row {...rowVehicleStyle}>
                  <FormField
                    bg="input_filled_bg"
                    colSize="12"
                    label={numberOfSeats}
                    inputValue={slots}
                    inputType="text"
                    inputName="seats"
                    placeholder={enterSeats}
                    required={true}
                    tabIndex="1"
                    disabled
                  />
                </Row>
              </Col>
              <Col size={{ xs: '6', sm: '4', lg: '2' }}>
                <Row {...rowVehicleStyle}>
                  <FormField
                    bg="input_filled_bg"
                    colSize="12"
                    label={cargoWeightLabel}
                    inputValue={cargoWeight}
                    inputType="text"
                    inputName="cargoWeight"
                    placeholder={enterWeight}
                    required={true}
                    tabIndex="1"
                    disabled
                  />
                </Row>
              </Col>
              <Col
                size={{ xs: '3', sm: '2', lg: '1' }}
                align="flex-end"
                justify="flex-end"
              >
                <Row {...rowVehicleStyle} p={{ xs: '.5rem', sm: '0' }}>
                  <Label
                    align="center"
                    textWeight="600"
                    m={{ b: { xs: '1rem', sm: '0' } }}
                  >
                    <Checkbox name="transit" checked={transit} disabled />
                    <FormattedMessage id="transit" />
                  </Label>
                </Row>
              </Col>
              <Col size="1" align="flex-end" justify="flex-end">
                <Row {...rowVehicleStyle}>
                  <IconButtonPrint>
                    <ConsigneePassToPrint
                      consignee={{
                        name,
                        slots,
                        cargoWeight,
                        transit,
                        departureCountry,
                        products,
                        vehiclePlateNumber,
                        arrivalDate,
                      }}
                    />
                  </IconButtonPrint>
                </Row>
              </Col>
              <Col size={{ xs: '12', lg: '6' }}>
                <Row {...rowVehicleStyle} maxW="600px">
                  <FormField
                    bg="input_filled_bg"
                    colSize="12"
                    label={productsLabel}
                    inputValue={products}
                    inputType="text"
                    inputName="products"
                    placeholder={productsLabel}
                    required={false}
                    tabIndex="7"
                    disabled
                  />
                </Row>
              </Col>
              <Col
                size={{ xs: '12', sm: '8', lg: '4' }}
                style={{ padding: '0' }}
              >
                <FormField
                  bg="input_filled_bg"
                  colSize="12"
                  label={departureCountryLabel}
                  inputValue={departureCountryValue}
                  inputType="text"
                  inputName="departureCountry"
                  placeholder={departureCountryLabel}
                  required={false}
                  tabIndex="8"
                  disabled
                />
              </Col>
              {status === VehicleStatus.DEPARTED && (
                <Col
                  size={{ xs: '6', sm: '4', lg: '2' }}
                  align="flex-end"
                  justify="flex-end"
                >
                  <Row {...rowVehicleStyle}>
                    <FormField
                      bg="input_filled_bg"
                      colSize="12"
                      label={
                        <FormattedMessage id="writeDynamicContractValue" />
                      }
                      inputValue={`${invoice?.paymentAmount} сом` || 0}
                      inputType="text"
                      inputName="sum"
                      placeholder={formatMessage('writeDynamicContractValue')}
                      required={false}
                      tabIndex="8"
                      disabled
                    />
                  </Row>
                </Col>
              )}
              {invoice?.applicationNumber && (
                <Col size={{ xs: '10', sm: '5', lg: '4' }}>
                  <Row {...rowVehicleStyle}>
                    <FormField
                      bg="input_filled_bg"
                      colSize="12"
                      label={<FormattedMessage id="applicationNumber" />}
                      inputValue={invoice?.applicationNumber || ''}
                      inputType="text"
                      inputName="applicationNumber"
                      placeholder={formatMessage(
                        'applicationNumberPlaceholder',
                      )}
                      required={false}
                      tabIndex="8"
                      disabled
                    />
                  </Row>
                </Col>
              )}
              {invoice?.declarationNumber && (
                <Col size={{ xs: '10', sm: '5', lg: '4' }}>
                  <Row {...rowVehicleStyle}>
                    <FormField
                      bg="input_filled_bg"
                      colSize="12"
                      label={<FormattedMessage id="declarationNumber" />}
                      inputValue={
                        validateDeclarationNumber(
                          `${invoice?.declarationNumber}//`,
                        ).mask
                      }
                      inputType="text"
                      inputName="declarationNumber"
                      placeholder={formatMessage(
                        'declarationNumberPlaceholder',
                      )}
                      required={false}
                      tabIndex="8"
                      disabled
                    />
                  </Row>
                </Col>
              )}
              {invoice?.file && (
                <Col size={{ xs: '2' }}>
                  <Row {...rowVehicleStyle}>
                    <Anchor
                      w="max-content"
                      textColor="black"
                      d="flex"
                      align="center"
                      href={invoice?.file || undefined}
                      target="_blank"
                      transform="translateY(0.3rem)"
                    >
                      <ClipIcon width={24} height={24} />
                      {invoice?.file ? '' : 'No file'}
                    </Anchor>
                  </Row>
                </Col>
              )}
            </Row>
          );
        },
      )}
      {status === VehicleStatus.DEPARTED && (
        <Row p={{ l: '1.5rem' }}>
          <Col>
            <Text tag="h2" textSize="subheader" m={{ y: '20px' }}>
              <FormattedMessage id="finalSum" />: {totalSum}сом
            </Text>
          </Col>
        </Row>
      )}
    </form>
  );
}
