/* External dependencies */
import * as React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function CheckIcon({ height, width, fill }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9959 12.6323H25.9999C26.7346 12.6323 27.3319 13.227 27.3332 13.9617C27.3439 17.523 25.9666 20.8763 23.4546 23.4017C20.9439 25.927 17.5999 27.323 14.0386 27.3337H13.9999C10.4519 27.3337 7.11456 25.9577 4.59857 23.455C2.07323 20.9443 0.677232 17.6003 0.666565 14.039C0.655899 10.4763 2.03323 7.12433 4.54523 4.59899C7.0559 2.07366 10.3999 0.677659 13.9612 0.666992C15.0212 0.682992 16.1012 0.789659 17.1359 1.03766C17.8506 1.21099 18.2919 1.93099 18.1186 2.64699C17.9466 3.36166 17.2226 3.80166 16.5106 3.63099C15.6826 3.43099 14.8039 3.34699 13.9692 3.33366C11.1199 3.34166 8.4439 4.45899 6.4359 6.47899C4.42656 8.49899 3.32523 11.1817 3.33323 14.031C3.34123 16.8803 4.45856 19.555 6.47856 21.5643C8.4919 23.5657 11.1612 24.667 13.9999 24.667H14.0306C16.8799 24.659 19.5559 23.5417 21.5639 21.5217C23.5732 19.5003 24.6746 16.819 24.6666 13.9697C24.6652 13.2337 25.2599 12.6337 25.9959 12.6323ZM9.05723 13.0575C9.57856 12.5362 10.4212 12.5362 10.9426 13.0575L13.9346 16.0495L22.3306 6.45486C22.8159 5.90419 23.6572 5.84553 24.2119 6.33086C24.7652 6.81486 24.8212 7.65753 24.3359 8.21219L15.0026 18.8789C14.7599 19.1562 14.4132 19.3202 14.0439 19.3335H13.9999C13.6466 19.3335 13.3079 19.1935 13.0572 18.9429L9.05723 14.9429C8.5359 14.4215 8.5359 13.5789 9.05723 13.0575Z"
        fill={fill}
      />
    </svg>
  );
}
