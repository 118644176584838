/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

/* Local dependencies */
import {
  DATE_FORMAT_FULL_YEAR,
  DEFAULT_TIMEZONE,
} from '../../common/renderDate';
import { Consignee } from '../../create-cargo/redux/actions';

interface ActTemporaryStorageToPrintProps {
  consignee: Consignee;
  organization: string;
}

export default function ActTemporaryStorageToPrint(
  props: ActTemporaryStorageToPrintProps,
) {
  const { consignee, organization } = props;

  const { name, products, slots } = consignee;

  const warehouseName = consignee.warehouse?.name;
  const weight = consignee.cargoWeight;

  return (
    <Div p={{ t: '42.5pt', r: '42.5pt', b: '37pt', l: '85pt' }}>
      <Row>
        <HeaderSide
          title="ТАМОЖЕННЫЙ ТЕРМИНАЛ"
          desc="Кыргызская Республика, г. Бишкек ул. Кожевенная-74, тел.(0312) 37-78-29"
        />
        <HeaderSide
          title={organization}
          desc="ИНН 00610200610298, Филиал ОАО «Оптима Банк» в г.Бишкек № 1, БИК 109008 р/с 1090820114770207, ОКПО 24285527"
        />
      </Row>
      <Row>
        <Col>
          <Text
            m={{ t: '14pt' }}
            textAlign="right"
            textSize="12pt"
            textDecor="underline"
            style={{ fontStyle: 'italic' }}
          >
            Форма СВХ-01 ГРУЗ
          </Text>
          <TopLine
            text="Передал: ___________________________________________"
            desc="(Ф.И.О. лица поместившего на СВХ согласно доверенности)"
          />
          <TopLine
            text="Принял: Завсклад ТТ: ______________________________"
            desc="(Фамилия и инициалы, подпись)"
          />
          <TopLine text="Время передачи: ___________" />
          <ActTitle />
          <NowDate mt="14pt" />
          <ActDescription
            data={{
              organization,
              name,
              products,
              slots,
              weight,
              warehouseName,
            }}
          />
          <ProductsList products={products} />
          <RepresentativeInfo
            text="Представитель передающей стороны:"
            desc="(полное наименование фирмы, ЧП или физического лица, подпись,
            печать)"
            mt="14pt"
          />
          <Text m={{ t: '3pt' }} textSize="12pt" textAlign="right">
            Тел._____________________________
          </Text>
          <RepresentativeInfo
            text="Сотрудник склада:"
            desc="(фамилия и инициалы, подпись)"
            mt="2pt"
          />
          <FooterSign text="Разгрузку на склад разрешаю" />
          <FooterSign text="Загрузку со склада разрешаю" />
        </Col>
      </Row>
    </Div>
  );
}

function NowDate({ mt }) {
  return (
    <Text m={{ t: mt }} textSize="12pt" textWeight="bold" textAlign="right">
      <FormattedMessage
        id="year"
        values={{
          year: moment()
            .utcOffset(DEFAULT_TIMEZONE)
            .format(DATE_FORMAT_FULL_YEAR),
        }}
      />
    </Text>
  );
}

export function HeaderSide({ title, desc }) {
  return (
    <Col>
      <Text maxW="227pt" textAlign="center" textWeight="bold" textSize="12pt">
        {title}
      </Text>

      <Text m={{ t: '14pt' }} maxW="227pt" textAlign="center" textSize="9pt">
        {desc}
      </Text>
    </Col>
  );
}

function TopLine({ text, desc }) {
  return (
    <>
      <Text m={{ t: '14pt' }} textSize="12pt" textWeight="bold">
        {text}
      </Text>
      {desc && (
        <Text
          m={{ t: '1pt' }}
          textSize="8pt"
          textAlign="center"
          style={{ fontStyle: 'italic' }}
        >
          {desc}
        </Text>
      )}
    </>
  );
}

function ActTitle() {
  return (
    <Text
      m={{ t: '28pt' }}
      textSize="12pt"
      textWeight="bold"
      textAlign="center"
    >
      <Text textSize="18pt" tag="span">
        АКТ
      </Text>
      <br />
      ХРАНЕНИЯ ГРУЗА <br /> НА СКЛАДЕ ВРЕМЕННОГО ХРАНЕНИЯ
    </Text>
  );
}

function ActDescription({ data }) {
  const { organization, name, products, slots, weight, warehouseName } = data;

  return (
    <Text m={{ t: '14pt' }} textSize="12pt" style={{ textIndent: '36pt' }}>
      <FormattedMessage
        id="actTemporaryStorageDocument"
        values={{
          terminalName: organization,
          nowDate: moment()
            .utcOffset(DEFAULT_TIMEZONE)
            .format(DATE_FORMAT_FULL_YEAR),
          organization: name,
          products: products,
          slots: slots,
          weight: weight,
          warehouseName: warehouseName,
        }}
      />
    </Text>
  );
}

function ProductsList({ products }) {
  return (
    <>
      <Text
        m={{ t: '14pt' }}
        textSize="12pt"
        textWeight="bold"
        style={{ textIndent: '36pt' }}
      >
        Наименование товара:
      </Text>
      {products.map((item, index) => (
        <Text key={index} m={{ t: '14pt' }} textSize="12pt">
          {`${index + 1})   ${item}`}
        </Text>
      ))}
    </>
  );
}

function RepresentativeInfo({ text, desc, mt }) {
  return (
    <>
      <Text
        m={{ t: mt }}
        textSize="12pt"
        textWeight="bold"
        style={{ textIndent: '36pt' }}
      >
        {text}
      </Text>
      <Div p={{ t: '22pt' }} style={{ borderBottom: '1px black solid' }}></Div>
      <Text
        m={{ t: '1pt' }}
        textSize="9pt"
        textAlign="center"
        style={{ fontStyle: 'italic' }}
      >
        {desc}
      </Text>
    </>
  );
}

function FooterSign({ text }): JSX.Element {
  return (
    <>
      <Text m={{ t: '14pt' }} textSize="12pt" textWeight="bold">
        {text}:__________/_________________/_______
      </Text>
      <Text
        textSize="9pt"
        textAlign="right"
        m={{ t: '1pt' }}
        style={{ fontStyle: 'italic' }}
      >
        (должность)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Фамилия и
        инициалы&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Подпись&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Text>
      <NowDate mt="7pt" />
    </>
  );
}
