/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import { getDefaultClient } from '../../clients/bts';
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import BasicButton from '../buttons/BasicButton';
import PrimaryButton from '../buttons/PrimaryButton';
import { CargoState } from '../cargo-detail/redux/reducer';
import {
  getUserRole,
  isAdmin,
  isManagerOperator,
  isOperator,
  isOperatorWeighman,
  isWeighman,
} from '../common/helpers';
import getFullName from '../common/renderName';
import { UserRoles } from '../common/roles';
import { BORDER_CHECKPOINTS } from '../countries/borderCheckpoints';
import {
  VehicleArrivalPurpose,
  VehicleStatus,
  WeightType,
} from '../create-vehicle/vehicles_types';
import DropdownPicker, {
  Alignment,
  LabelStyle,
} from '../custom-dropdown/DropdownPicker';
import FormField from '../form/FormField';
import { inputCurrentWeightStyle, rowVehicleStyle } from '../form/FormStyles';
import ImagesVehicle from '../images-vehicle/ImagesVehicle';
import { PaymentStatus } from '../invoices/invoice/common/invoice-types';
import Notification from '../notification';
import {
  onUpdateDeviceSubscribedSuccessfully,
  onUpdateDeviceSubscribeFailed,
  onUpdateDeviceUnsubscribe,
  saveSubscriptionToDevice,
} from '../on-update-device/action';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import BasicButtonPrint from '../print/BasicButtonPrint';
import WeightCardToPrint from '../print/WeightCardToPrint';
import { getUser } from '../user-details/redux/epics';
import {
  updateVehicleRequest,
  updateVehicleAddInfo,
  resetError,
  getVehicle,
  resetVehicleDetails,
} from './redux/actions';
import { VehicleState } from './redux/reducer';
import { onDeviceUpdateSubscription } from './redux/subscription';
import { getUserFields, isFieldRequired } from './user-fields/common';

export default function VehicleDetailsForm() {
  const dispatch = ReactRedux.useDispatch();

  const {
    isAdded,
    actFormNumberError,
    borderCheckpointError,
    driverNameError,
    driverPhoneError,
    editableFields,
    error,
    grossWeightError,
    netWeightError,
    isFormChanged,
    loading,
    representativeNameError,
    representativeRoleError,
    representativePhoneError,
    saving,
    tareWeightError,
    trailerPlateNumberError,
    vehicle,
    vehiclePlateNumberError,
  } = ReactRedux.useSelector((state: RootState): VehicleState => state.vehicle);
  const { cargo } = ReactRedux.useSelector(
    (state: RootState): CargoState => state.cargo,
  );
  const { currentUser } = ReactRedux.useSelector(
    (state: RootState) => state.login,
  );
  const { subscription } = ReactRedux.useSelector(
    (state: RootState) => state.onUpdateDevice,
  );

  const [isConfirmationPopupVisible, setConfirmationState] = useState(false);
  const [isTare, setTare] = useState(false);
  const [isAgreeTare, setAgreeTare] = useState(false);
  const [departureOperator, setDepartureOperator] = useState('');
  const [cargoOperator, setCargoOperator] = useState('');
  const userFields = getUserFields(currentUser);

  useEffect(() => {
    if (vehicle && vehicle.departureOperator) {
      getUser({ id: vehicle?.departureOperator?.id }).then((user) => {
        setDepartureOperator(getFullName(user.firstName, user.lastName));
      });
    }
    if (cargo && cargo.operator) {
      getUser({ id: cargo?.operator?.id }).then((user) => {
        setCargoOperator(getFullName(user.firstName, user.lastName));
      });
    }
  }, [vehicle, cargo]);

  function openConfirmationPopup() {
    setConfirmationState(true);
  }

  function closeConfirmationPopup() {
    setConfirmationState(false);
  }

  function onSubmit() {
    dispatch(
      updateVehicleRequest({
        id: vehicle?.id,
        ...vehicle,
        vehicleProfileId: vehicle?.vehicleProfile?.id,
      }),
    );
  }

  function onInputChange(e) {
    const { name, value } = e.target;

    dispatch(updateVehicleAddInfo({ [name]: value }));
  }

  function registerDeparture() {
    dispatch(
      updateVehicleRequest({
        ...vehicle,
        vehicleProfileId: vehicle?.vehicleProfile?.id,
        status: VehicleStatus.DEPARTED,
      }),
    );
  }

  function subscribeToOnUpdateDevice() {
    const graphQLClient = getDefaultClient();
    const $updateDevice = graphQLClient.subscribe({
      query: onDeviceUpdateSubscription,
    });

    return $updateDevice.subscribe({
      next: (value: any) => {
        const {
          data: { onDeviceUpdate },
        } = value;

        dispatch(
          onUpdateDeviceSubscribedSuccessfully(onDeviceUpdate, WeightType.TARE),
        );
      },

      error: (error: any) => {
        dispatch(onUpdateDeviceSubscribeFailed(error));
      },
    });
  }

  function handleAgreeToChangeTareWeight() {
    setAgreeTare(true);
    dispatch(saveSubscriptionToDevice(subscribeToOnUpdateDevice()));
  }

  function handleChangeTareWeight() {
    subscription.unsubscribe();

    dispatch(onUpdateDeviceUnsubscribe());
    dispatch(
      updateVehicleRequest({
        id: vehicle?.id,
        tareWeight: vehicle?.tareWeight,
        vehiclePlateNumber: vehicle?.vehiclePlateNumber,
        vehicleProfileId: vehicle?.vehicleProfile?.id,
      }),
    );

    setAgreeTare(false);
    setTare(false);
  }

  function handleSuccessPopup() {
    const id = localStorage.getItem('vehicleId');

    dispatch(resetVehicleDetails());
    dispatch(getVehicle(id));
  }

  const rowStyle = {
    ...rowVehicleStyle,
    alignItems: 'baseline',
    borderTop: '1px solid #EDF1F7',
    paddingTop: '1rem',
  };

  const isInvalid = !!(
    actFormNumberError ||
    borderCheckpointError ||
    driverNameError ||
    driverPhoneError ||
    grossWeightError ||
    netWeightError ||
    representativeNameError ||
    representativeRoleError ||
    representativePhoneError ||
    tareWeightError ||
    trailerPlateNumberError ||
    vehiclePlateNumberError
  );

  // prettier-ignore
  const allowToUpdate =
    // Allow administrators to make changes any time.
    isAdmin(currentUser) ||
    // Allow operators and weighman to make limited changes
    // but only if the vehicle has not yet departured.
    (isManagerOperator(currentUser) || isWeighman(currentUser) || isOperatorWeighman(currentUser))
    && vehicle.status === VehicleStatus.ARRIVED;

  const allowToDeparture =
    (isOperator(currentUser) || isOperatorWeighman(currentUser)) &&
    vehicle.status === VehicleStatus.ARRIVED;

  const listOfPurposes = Object.keys(VehicleArrivalPurpose);
  const isGrossWeightZero = vehicle.grossWeight === 0;
  const tareWeightPlaceholder = formatMessage('tareWeightPlaceholder');
  const tareWeightLabel = formatMessage('tareWeight');

  return (
    <Row w="100%" p={{ l: '1.5rem' }}>
      <Col size={{ xs: '12', md: '10', lg: '6' }}>
        <Div m={{ b: { xs: '40px', lg: '0' } }}>
          {userFields?.map((field, key) => {
            const label = formatMessage(field.label);
            const placeholder = formatMessage(field.placeholder);
            let value = field.value
              ? field.value(vehicle)
              : vehicle[field.label];

            const inputType = typeof value === 'number' ? 'number' : 'text';
            const isEditable = (editableFields || []).includes(field.label);
            const isGrossWeight =
              isEditable && field.hasSeparator && field.label === 'grossWeight';

            if (
              !isEditable &&
              (typeof value === 'undefined' ||
                value === null ||
                (typeof value === 'string' && !value))
            ) {
              return null;
            }

            const formField =
              field.isDropdownCapable && isEditable ? (
                <DropdownPicker
                  labelSize={{ xs: '12', sm: '5' }}
                  inputName={field.label}
                  items={(function () {
                    switch (field.label) {
                      case 'arrivalPurpose':
                        return listOfPurposes;
                      default:
                        return BORDER_CHECKPOINTS;
                    }
                  })()}
                  label={label}
                  labelAlignment={Alignment.HORIZONTAL}
                  labelDropdownStyle={LabelStyle.PRIMARY}
                  onChange={onInputChange}
                  text="borderCheckpointPlaceholder"
                  value={vehicle[field.label]}
                />
              ) : (
                <FormField
                  bg={!isEditable ? 'input_filled_bg' : 'input_bg'}
                  colSize={isGrossWeight ? '12' : '7'}
                  disabled={!isEditable || field.label === 'grossWeight'}
                  label={label}
                  inputStyle={isGrossWeight ? inputCurrentWeightStyle : ''}
                  inputValue={
                    field.isDropdownCapable ? formatMessage(value) : value
                  }
                  inputType={inputType}
                  inputName={field.label}
                  onChange={onInputChange}
                  placeholder={placeholder}
                  required={isFieldRequired(field.label)}
                  tabIndex="1"
                />
              );
            if (field.hasSeparator) {
              return field.label === 'grossWeight' ? (
                <Row
                  key={key}
                  d={!isGrossWeightZero && !isEditable ? 'none' : 'flex'}
                  style={rowStyle}
                >
                  <Div maxW="100%">{formField}</Div>
                </Row>
              ) : (
                <Row style={rowStyle} key={key}>
                  {formField}
                </Row>
              );
            }
            if (field.isCheckbox) {
              return (
                <Row key={key}>
                  <Col size={{ xs: '12', sm: '9' }}>
                    <Row align="baseline">
                      <FormField
                        bg={!isEditable ? 'input_filled_bg' : 'input_bg'}
                        colSize={'6'}
                        disabled
                        label={label}
                        colSizeLabel={'6'}
                        inputStyle={
                          isGrossWeight ? inputCurrentWeightStyle : ''
                        }
                        inputValue={
                          field.isDropdownCapable ? formatMessage(value) : value
                        }
                        inputType={inputType}
                        inputName={field.label}
                        placeholder={placeholder}
                        required={isFieldRequired(field.label)}
                        tabIndex="1"
                        divStyles={{ p: { l: { sm: '12%' } } }}
                      />
                    </Row>
                  </Col>
                  <Col size={{ xs: '12', sm: '3' }}>
                    <Row m={{ b: '1rem' }}>
                      <BasicButton
                        disabled={isTare}
                        loading={false}
                        onClick={() => {
                          setTare(!isTare);
                        }}
                        textColor="text_color"
                        size="md"
                      >
                        <FormattedMessage id="tare" />
                      </BasicButton>
                    </Row>
                  </Col>
                </Row>
              );
            }
            return field.label === 'grossWeight' ? (
              <Row
                {...rowVehicleStyle}
                align="baseline"
                d={
                  !isGrossWeightZero && !isEditable && !field.hasSeparator
                    ? 'flex'
                    : 'none'
                }
                key={key}
                flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
              >
                {formField}
              </Row>
            ) : (
              <Row
                {...rowVehicleStyle}
                align="baseline"
                flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                key={key}
              >
                {formField}
              </Row>
            );
          })}
          {departureOperator && (
            <Row align="baseline" flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
              <FormField
                bg={'input_filled_bg'}
                colSize={'7'}
                disabled
                label={formatMessage('departureRegistered')}
                inputValue={departureOperator}
                inputName={'departureRegistered'}
                placeholder={'Оператор не зарегистрирован'}
                tabIndex="1"
              />
            </Row>
          )}
          {cargoOperator && (
            <Row align="baseline" flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
              <FormField
                bg={'input_filled_bg'}
                colSize={'7'}
                disabled
                label={formatMessage('cargoRegistered')}
                inputValue={cargoOperator}
                inputName={'cargoRegistered'}
                placeholder={'Оператор не зарегистрирован'}
                tabIndex="1"
              />
            </Row>
          )}

          {isTare && !isAgreeTare && (
            <PrimaryPopup
              title={<FormattedMessage id="agreeToChangeTareWeight" />}
              cancelBtnText={<FormattedMessage id="cancel" />}
              onClose={() => {
                setTare(false);
              }}
              btnText={<FormattedMessage id="tare" />}
              loading={loading}
              onClick={handleAgreeToChangeTareWeight}
            >
              <Text>
                <FormattedMessage id="priceTareWeightText" />
              </Text>
            </PrimaryPopup>
          )}
          {isAgreeTare && (
            <PrimaryPopup
              title={
                <FormattedMessage
                  id="tareAuto"
                  values={{ number: vehicle?.vehiclePlateNumber }}
                />
              }
              cancelBtnText={<FormattedMessage id="cancel" />}
              onClose={() => {
                subscription.unsubscribe();

                dispatch(onUpdateDeviceUnsubscribe());
                setAgreeTare(false);
                setTare(false);
              }}
              btnText={<FormattedMessage id="save" />}
              loading={loading}
              onClick={handleChangeTareWeight}
            >
              <Row>
                <FormField
                  label={tareWeightLabel}
                  inputValue={vehicle?.tareWeight}
                  inputType="number"
                  inputName="tareWeight"
                  placeholder={tareWeightPlaceholder}
                  onChange={onInputChange}
                  tabIndex="10"
                  colSize="12"
                  colSizeLabel="12"
                  disabled
                />
              </Row>
            </PrimaryPopup>
          )}
          {isConfirmationPopupVisible && !isAdded && (
            <PrimaryPopup
              title={<FormattedMessage id="saveChangesQuestion" />}
              cancelBtnText={<FormattedMessage id="cancel" />}
              onClose={closeConfirmationPopup}
              btnText={<FormattedMessage id="save" />}
              loading={loading}
              onClick={onSubmit}
            >
              {}
            </PrimaryPopup>
          )}
          {isAdded && (
            <SuccessPopup
              onSubmit={handleSuccessPopup}
              submitText={<FormattedMessage id="close" />}
              title={<FormattedMessage id="changesSaved" />}
            >
              <Div>
                <Text>
                  <FormattedMessage id="Vehicle" /> -
                  {vehicle.vehiclePlateNumber}
                </Text>
                {vehicle.trailerPlateNumber && (
                  <Text>
                    <FormattedMessage id="Trailer" /> -
                    {vehicle.trailerPlateNumber}
                  </Text>
                )}
                <Text>
                  <FormattedMessage id="driver" /> - {vehicle.driver.name}
                </Text>
              </Div>
            </SuccessPopup>
          )}
          {isConfirmationPopupVisible && error && (
            <ErrorPopup
              onSubmit={() => dispatch(resetError())}
              submitText={<FormattedMessage id="close" />}
              title={
                <FormattedMessage
                  id="errorOccurred"
                  values={{ name: 'errorOccurred' }}
                />
              }
            >
              <Div className="ErrorPopupTest">
                <FormattedMessage id={error.code} />
              </Div>
            </ErrorPopup>
          )}
          {getUserRole(currentUser) === UserRoles.BTS_OPERATOR_WEIGHMAN && (
            <Row m={{ t: '24px' }}>
              {vehicle.paymentStatus !== PaymentStatus.UNPAID ? (
                <Notification
                  type="success"
                  title={formatMessage('departureIsAllowed')}
                >
                  <FormattedMessage id="alreadyPaid" />
                </Notification>
              ) : (
                <Notification
                  type="danger"
                  title={formatMessage('departureIsProhibited')}
                >
                  <FormattedMessage id="unpaidYet" />
                </Notification>
              )}
            </Row>
          )}
          {allowToUpdate && (
            <Row m={{ y: '24px' }}>
              <PrimaryButton
                icon="Checked"
                disabled={isInvalid || saving || !isFormChanged}
                loading={saving}
                onClick={openConfirmationPopup}
                size="lg"
              >
                <FormattedMessage id="save" />
              </PrimaryButton>
            </Row>
          )}
          {allowToDeparture && (
            <Row m={{ y: '24px' }}>
              <PrimaryButton
                icon="Checked"
                disabled={
                  saving || vehicle.paymentStatus === PaymentStatus.UNPAID
                }
                loading={saving}
                onClick={registerDeparture}
                size="lg"
              >
                <FormattedMessage id="registerDeparture" />
              </PrimaryButton>
            </Row>
          )}
          {(isWeighman(currentUser) || isOperatorWeighman(currentUser)) && (
            <Row>
              <Col>
                <BasicButtonPrint
                  textForBtn={<FormattedMessage id="printWeightCard" />}
                >
                  <WeightCardToPrint item={vehicle} />
                </BasicButtonPrint>
              </Col>
            </Row>
          )}
        </Div>
      </Col>
      <Col size={{ xs: '12', lg: '6' }}>
        <ImagesVehicle images={vehicle?.images} />
      </Col>
    </Row>
  );
}
