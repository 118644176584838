/* External dependencies */
import React from 'react';

/* Local dependencies */
import DateTime from '../../common/DateTime';
import { getCurrentTimeStamp } from '../../common/getCurrentTimeStamp';
import '../index.scss';

export default function DateToPrint() {
  return <DateTime date={getCurrentTimeStamp()} />;
}
