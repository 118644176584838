/* External dependencies */
import { Col, Container, Div, Label, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import CheckIcon from '../../assets/svg/checkIcon';
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import AsyncSelect from '../async-select/AsyncSelect';
import PrimaryButton from '../buttons/PrimaryButton';
import { getCargo } from '../cargo-detail/redux/actions';
import { listConsigneeProfiles } from '../consignee-profiles/redux/actions';
import { СonsigneeProfilesState } from '../consignee-profiles/redux/reducer';
import { COUNTRY_NAMES } from '../countries/countryNames';
import DropdownPicker, {
  Alignment,
  LabelStyle,
} from '../custom-dropdown/DropdownPicker';
import CustomSelect from '../custom-select/CustomSelect';
import FormField from '../form/FormField';
import { rowVehicleStyle } from '../form/FormStyles';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import { getVehicle } from '../vehicle-details/redux/actions';
import { VehicleState } from '../vehicle-details/redux/reducer';
import {
  CargoEscort,
  createCargoFailed,
  createCargoRequest,
  resetCreateCargo,
  resetError,
  updateCargoFields,
} from './redux/actions';
import { validateCargoNetWeightAndSlots } from './redux/validateCargo';

export interface CreateCargoFormProps {
  setSubMenuItem: any;
}

export default function CreateCargoForm({
  setSubMenuItem,
}: CreateCargoFormProps) {
  const cargoWeight = formatMessage('cargoWeight');
  const consigneeFormat = formatMessage('Consignee');
  const describeProduct = formatMessage('describeProduct');
  const departureCountry = formatMessage('departureCountry');
  const formatEscort = formatMessage('escort');
  const enterWeight = formatMessage('enterWeight');
  const enterCargoWeight = formatMessage('enterCargoWeight');
  const enterSeats = formatMessage('enterSeats');
  const numberOfSeats = formatMessage('numberOfSeats');
  const products = formatMessage('products');
  const totalCargoWeightNet = formatMessage('totalCargoWeightNet');
  const totalNumberOfSeats = formatMessage('totalNumberOfSeats');
  const escort = Object.keys(CargoEscort);

  const [isConfirmationPopupVisible, setConfirmationState] = useState(false);

  const dispatch = useDispatch();

  const {
    error,
    cargo,
    cargoNetWeightError,
    cargoSlotsError,
    consigneeNameError,
    cargoEscortError,
    loading,
    isSuccess,
  } = useSelector((state: RootState) => state.newCargo);

  const { vehicle } = useSelector(
    (state: RootState): VehicleState => state.vehicle,
  );

  const {
    consigneeProfiles,
    searchString,
    loading: listConsigneeProfilesLoading,
  } = useSelector(
    (state: RootState): СonsigneeProfilesState => state.consigneeProfiles,
  );

  function onSuccessSubmit() {
    setSubMenuItem('cargoDetails');
    dispatch(getVehicle(vehicle?.id));
    dispatch(getCargo(vehicle?.id));
    dispatch(resetCreateCargo());
  }

  function openConfirmationPopup(e) {
    e.preventDefault();
    setConfirmationState(true);
  }

  function closeConfirmationPopup() {
    setConfirmationState(false);
  }

  function onSubmit() {
    const error = validateCargoNetWeightAndSlots(cargo);
    setConfirmationState(false);
    if (error) {
      dispatch(createCargoFailed(error));
      return;
    }
    cargo.vehicle = vehicle;

    dispatch(createCargoRequest(cargo));
  }

  function onInputChange(e) {
    const { name, value } = e.target;

    dispatch(updateCargoFields({ [name]: value }));
  }

  const countries = COUNTRY_NAMES.map((country) => ({
    label: formatMessage(country),
    value: country,
  }));

  function onChange(e, changedFieldIndex: number) {
    const newConsignees: any[] = [];
    let { name, value, label, checked } = e.target;

    // Copy over previous items in the array.
    changedFieldIndex &&
      newConsignees.push.apply(
        newConsignees,
        consignees.slice(0, changedFieldIndex),
      );

    if (label) {
      newConsignees.push({
        ...consignees[changedFieldIndex],
        [name]: value,
        name: label,
      });
    } else if (checked) {
      newConsignees.push({
        ...consignees[changedFieldIndex],
        transit: !checked,
      });
    } else {
      newConsignees.push({
        ...consignees[changedFieldIndex],
        [name]: value,
      });
    }

    // Copy over the rest items in the array.
    changedFieldIndex < consignees.length - 1 &&
      newConsignees.push.apply(
        newConsignees,
        consignees.slice(changedFieldIndex + 1),
      );

    onInputChange({
      target: {
        name: 'consignees',
        value: newConsignees,
      },
    });
  }

  const consignees = [
    ...cargo.consignees,
    {
      cargoWeight: null,
      profileId: '',
      departureCountry: '',
      name: '',
      products: '',
      slots: null,
      transit: false,
    },
  ];

  function onSearch(e, index) {
    dispatch(listConsigneeProfiles(e.target.value, 1));

    onChange(e, index);
  }

  const isInvalid =
    !cargo.cargoNetWeight ||
    !cargo.cargoSlots ||
    !cargo.consignees[0] ||
    cargoNetWeightError ||
    cargoSlotsError ||
    consigneeNameError ||
    cargoEscortError;

  const colSize = '7';
  const borderColor = 'search_bg_color';

  return (
    <Container>
      <form onSubmit={openConfirmationPopup}>
        <Row>
          <Col size={{ xs: '12', lg: '6' }} maxW="600px" w="100%">
            <Row {...rowVehicleStyle} align="baseline" p={{ t: '1rem' }}>
              <FormField
                colSize={colSize}
                error={cargoNetWeightError}
                label={totalCargoWeightNet}
                inputValue={cargo.cargoNetWeight}
                inputType="number"
                inputName="cargoNetWeight"
                placeholder={enterCargoWeight}
                onChange={onInputChange}
                required={true}
                tabIndex="1"
              />
            </Row>
            <Row {...rowVehicleStyle} align="baseline" p={{ t: '1rem' }}>
              <FormField
                colSize={colSize}
                error={cargoSlotsError}
                label={totalNumberOfSeats}
                inputValue={cargo.cargoSlots}
                inputType="number"
                inputName="cargoSlots"
                placeholder={enterSeats}
                onChange={onInputChange}
                required={true}
                tabIndex="2"
              />
            </Row>
            <Row
              {...rowVehicleStyle}
              align="baseline"
              border={{ b: '1px solid' }}
              borderColor={borderColor}
              p={{ t: '1rem' }}
            >
              <DropdownPicker
                labelSize={{ xs: '12', sm: '5' }}
                inputName="cargoEscort"
                items={escort}
                label={formatEscort}
                labelAlignment={Alignment.HORIZONTAL}
                labelDropdownStyle={LabelStyle.PRIMARY}
                onChange={onInputChange}
                text={escort[0]}
                value={cargo.cargoEscort}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text tag="h2" textSize="subheader" m={{ y: '20px' }}>
              <FormattedMessage id="Consignees" />
            </Text>
          </Col>
        </Row>
        {consignees.map((value, index) => {
          return (
            <Row
              {...rowVehicleStyle}
              align="center"
              p={{ t: '1rem' }}
              key={index}
            >
              <Col size={{ xs: '12', lg: '6' }}>
                <Row {...rowVehicleStyle} maxW="600px" w="100%">
                  <AsyncSelect
                    labelSize={'12'}
                    inputName={
                      consigneeProfiles?.length === 0 ? 'name' : 'profileId'
                    }
                    items={consigneeProfiles.map(({ name, id }) => ({
                      label: name,
                      value: id,
                    }))}
                    label={consigneeFormat}
                    labelAlignment={Alignment.HORIZONTAL}
                    labelDropdownStyle={LabelStyle.PRIMARY}
                    loading={listConsigneeProfilesLoading}
                    onChange={(e) => onChange(e, index)}
                    onSearch={(e) => onSearch(e, index)}
                    required={true}
                    searchString={searchString}
                    value={
                      consigneeProfiles?.length === 0
                        ? value.name
                        : value.profileId
                    }
                  />
                </Row>
              </Col>
              <Col
                size={{ xs: '12', sm: '4', lg: '2' }}
                style={{ padding: '0' }}
              >
                <Row {...rowVehicleStyle} p={{ x: '8px' }}>
                  <FormField
                    colSize="12"
                    label={numberOfSeats}
                    inputValue={value.slots}
                    inputType="number"
                    inputName="slots"
                    placeholder={enterSeats}
                    onChange={(e) => onChange(e, index)}
                    required={false}
                    tabIndex="4"
                  />
                </Row>
              </Col>
              <Col size={{ xs: '12', sm: '4', lg: '2' }} p={{ x: '8px' }}>
                <Row {...rowVehicleStyle}>
                  <FormField
                    colSize="12"
                    label={cargoWeight}
                    inputValue={value.cargoWeight}
                    inputType="number"
                    inputName="cargoWeight"
                    placeholder={enterWeight}
                    onChange={(e) => onChange(e, index)}
                    required={false}
                    tabIndex="5"
                  />
                </Row>
              </Col>
              <Col size={{ xs: '12', sm: '4', lg: '2' }}>
                <Div
                  className="checkbox-input"
                  d="flex"
                  align="center"
                  m={{ b: { xs: '1rem', sm: '0' }, l: { xs: '2px' } }}
                >
                  <Div>
                    <Label
                      align="center"
                      justify="center"
                      d="flex"
                      className={`checkbox-label ${value.transit && 'active'}`}
                    >
                      <CheckIcon />
                    </Label>
                    <input
                      type="checkbox"
                      name="transit"
                      onChange={(e) => onChange(e, index)}
                    />
                  </Div>
                  <Text textWeight="600" m={{ l: '5px' }}>
                    <FormattedMessage
                      id="transit"
                      values={{ name: 'transit' }}
                    />
                  </Text>
                </Div>
              </Col>
              <Col size={{ xs: '12', lg: '6' }}>
                <Row {...rowVehicleStyle} maxW="600px" w="100%">
                  <FormField
                    colSize="12"
                    label={products}
                    inputValue={value.products}
                    inputType="text"
                    inputName="products"
                    placeholder={describeProduct}
                    onChange={(e) => onChange(e, index)}
                    required={false}
                    tabIndex="7"
                  />
                </Row>
              </Col>
              <Col
                size={{ xs: '12', sm: '8', lg: '4' }}
                style={{ padding: '0' }}
              >
                <CustomSelect
                  labelSize={'5'}
                  inputName="departureCountry"
                  items={countries}
                  label={departureCountry}
                  labelAlignment={Alignment.HORIZONTAL}
                  labelDropdownStyle={LabelStyle.PRIMARY}
                  onChange={(e) => onChange(e, index)}
                  required={true}
                  text="departureCountryPlaceholder"
                  value={value.departureCountry}
                />
              </Col>
            </Row>
          );
        })}
        <Row {...rowVehicleStyle} align="baseline" p={{ t: '2rem' }}>
          <Col size={{ xs: '12', lg: '6' }} maxW="600px">
            <PrimaryButton
              icon="Checked"
              size="lg"
              disabled={isInvalid || loading}
              onClick={() => openConfirmationPopup}
              loading={loading}
            >
              {<FormattedMessage id="registerCargo" />}
            </PrimaryButton>
          </Col>
        </Row>
        {isConfirmationPopupVisible && (
          <PrimaryPopup
            title={<FormattedMessage id="registerCargoQuestion" />}
            cancelBtnText={<FormattedMessage id="cancel" />}
            onClose={closeConfirmationPopup}
            btnText={<FormattedMessage id="register" />}
            loading={loading}
            onClick={onSubmit}
          />
        )}
        {isSuccess && !isInvalid && (
          <SuccessPopup
            onSubmit={onSuccessSubmit}
            submitText={<FormattedMessage id="close" />}
            title={<FormattedMessage id="cargoAdded" />}
          >
            <Div>
              <Text>
                {<FormattedMessage id="Vehicle" />}
                {` -  ${vehicle?.vehiclePlateNumber}`}
              </Text>
              {vehicle?.trailerPlateNumber && (
                <Text>
                  {<FormattedMessage id="Trailer" />}
                  {` -  ${vehicle?.trailerPlateNumber}`}
                </Text>
              )}
              <Text>
                {<FormattedMessage id="driver" />}
                {` -  ${vehicle?.driver?.name}`}
              </Text>
            </Div>
          </SuccessPopup>
        )}
        {error && (
          <ErrorPopup
            onSubmit={() => dispatch(resetError())}
            submitText={<FormattedMessage id="close" />}
            title={<FormattedMessage id="errorOccurred" />}
          >
            {formatMessage(error.code)}
          </ErrorPopup>
        )}
      </form>
    </Container>
  );
}
