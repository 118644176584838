/* External dependencies */
import { Col, Row, Text } from 'atomize';
import React from 'react';

/* Local dependencies */
import '../index.scss';

export interface TitleToPrintProps {
  title?: React.ReactChild;
  className?: string;
}

export default function TitleToPrint({ title, className }: TitleToPrintProps) {
  return (
    <Row className={className}>
      <Col>
        <Text>{title}</Text>
      </Col>
    </Row>
  );
}
