/* External dependencies */
import { Col, Div, Image, Row, Text } from 'atomize';
import React from 'react';

/* Local dependencies */
import DateTime from '../../common/DateTime';
import renderName from '../../common/renderName';
import { WeightType } from '../../create-vehicle/vehicles_types';
import { HeaderSide } from '../ActTemporaryStorageToPrint';
import DateToPrint from '../printComponents/DateToPrint';
import '../index.scss';

interface WeightCardToPrintProps {
  item: any;
}

export default function WeightCardToPrint({ item }: WeightCardToPrintProps) {
  const vehicle = item;
  const rowArrayComponent: any = [];
  const netWeightImages = vehicle.images?.filter(
    (item) => item.type === WeightType.GROSS,
  );
  const grossWeightImages = vehicle.images?.filter(
    (item) => item.type === WeightType.TARE,
  );

  rowArrayComponent.push(
    {
      label: 'ДАТА и время въезда:',
      value: <DateTime date={vehicle.arrivalDate} />,
      mt: '28pt',
    },
    {
      label: 'НОМЕР транспортного средства:',
      value: vehicle.vehiclePlateNumber,
    },
    {
      label: 'НОМЕР прицепа:',
      value: vehicle.trailerPlateNumber,
    },
    {
      label: 'ВОДИТЕЛЬ:',
      value: vehicle.driver.name,
    },
    {
      label: 'КОНТАКТНЫЕ данные водителя:',
      value: vehicle.driver.phone,
    },
    {
      label: 'ОТВЕТСТВЕННЫЙ за взвешивание:',
      value: renderName(vehicle.operator.firstName, vehicle.operator.lastName),
      mt: '28pt',
    },
    {
      label: 'БРУТТО:',
      value: vehicle.grossWeight,
      mt: '28pt',
      size: '2',
      nonItalic: true,
    },
    {
      label: 'ТАРА:',
      value: vehicle.tareWeight,
      mt: '3pt',
      size: '2',
      nonItalic: true,
    },
    {
      label: 'НЕТТО:',
      value: vehicle.netWeight,
      mt: '3pt',
      size: '2',
      nonItalic: true,
    },
  );

  return (
    <>
      <Div p={{ t: '42.5pt', r: '42.5pt', b: '37pt', l: '85pt' }}>
        <Row>
          <HeaderSide
            title="ТАМОЖЕННЫЙ ТЕРМИНАЛ"
            desc="Кыргызская Республика, г. Бишкек ул. Кожевенная-74, тел.(0312) 37-78-29"
          />
          <HeaderSide
            title={'ОсОО «БИЗНЕСТРАНССЕРВИС»'}
            desc="ИНН 00610200610298, Филиал ОАО «Оптима Банк» в г.Бишкек № 1, БИК 109008 р/с 1090820114770207, ОКПО 24285527"
          />
        </Row>
        <Row>
          <Col>
            <Text
              m={{ t: '14pt' }}
              textAlign="right"
              textSize="12pt"
              textDecor="underline"
              style={{ fontStyle: 'italic' }}
            >
              Форма СВХ-03 ГРУЗ
            </Text>
            <Text m={{ t: '14pt' }} textAlign="center" textSize="18pt">
              ВЕСОВАЯ КАРТОЧКА
            </Text>
            {rowArrayComponent.map((item, index) => (
              <Row key={index} m={{ t: item.mt || '14pt' }}>
                <Col size={item.size}>
                  <Text
                    textSize="12pt"
                    textWeight="bold"
                    style={{ fontStyle: !item.nonItalic && 'italic' }}
                  >
                    {item.label}
                  </Text>
                </Col>
                <Col>
                  <Text
                    textSize="12pt"
                    style={{ fontStyle: !item.nonItalic && 'italic' }}
                  >
                    {item.value}
                  </Text>
                </Col>
              </Row>
            ))}
            {grossWeightImages?.length > 0 && (
              <>
                <Text m={{ t: '14pt' }} textAlign="center" textSize="12pt">
                  ФОТО карточка (БРУТТО)
                </Text>
                <Row justify="space-between" m={{ t: '7pt' }}>
                  {grossWeightImages.map((image, index) => (
                    <Col key={index} size="6">
                      <Div>
                        <Image src={image.url} className="weight-img_print" />
                      </Div>
                    </Col>
                  ))}
                </Row>
              </>
            )}
            {netWeightImages?.length > 0 && (
              <>
                <Text m={{ t: '3pt' }} textAlign="center" textSize="12pt">
                  ФОТО карточка (НЕТТО)
                </Text>
                <Row justify="space-between" m={{ t: '7pt' }}>
                  {netWeightImages.map((image, index) => (
                    <Col key={index} size="6">
                      <Div>
                        <Image src={image.url} className="weight-img_print" />
                      </Div>
                    </Col>
                  ))}
                </Row>
              </>
            )}
            <Text
              pos="fixed"
              bottom="40pt"
              right="45pt"
              fontSize="9pt"
              style={{ fontStyle: 'italic' }}
            >
              Распечатано: <DateToPrint />
            </Text>
          </Col>
        </Row>
      </Div>
    </>
  );
}
