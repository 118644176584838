/* External dependencies */
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

/* Local dependencies */
import Layout from '../../../components/layout';
import VehicleDetails from '../../../components/vehicle-details';
import { VehicleState } from '../../../components/vehicle-details/redux/reducer';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';

export default function VehicleDetailsPage({ data }) {
  const { vehicle } = useSelector(
    (state: RootState): VehicleState => state.vehicle,
  );

  const title = formatMessage('flightEntered');
  const vehicleTitle = vehicle ? vehicle.vehiclePlateNumber + title : '...';

  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', vehicleTitle)}</title>
      </Helmet>
      <VehicleDetails />
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
