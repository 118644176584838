/* External dependencies */
import * as React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function FlashIcon({ height, width }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.726 10.666h4.275a.833.833 0 01.828.923l-.374 3.458 3.82-5.714H10a.833.833 0 01-.829-.923l.374-3.457-3.82 5.713zm3.534 8.5a.831.831 0 01-.829-.922l.641-5.911H4.167a.834.834 0 01-.692-1.297l6.574-9.832c.212-.32.616-.452.976-.32.361.13.586.491.545.872l-.641 5.91h4.905a.834.834 0 01.692 1.297l-6.574 9.833a.832.832 0 01-.692.37z"
        fill="#fff"
      />
    </svg>
  );
}
