/* External dependencies */
import { Button, Div, Icon, Text } from 'atomize';
import React from 'react';

/* Local dependencies */
import { SessionUser } from '../login/redux/actions';
import { subMenuStyle } from '../form/FormStyles';
import { getUserRole } from '../common/helpers';
import { UserRoles } from '../common/roles';

interface SubMenuProps {
  isActive?: boolean;
  menuItem: string | any;
  onClick?: any;
  maxWidth?: string;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  edit?: boolean;
  isDelete?: boolean;
  error?: boolean;
}

export default function SubMenu(props: SubMenuProps) {
  const {
    isActive,
    menuItem,
    onClick,
    maxWidth,
    edit,
    isDelete,
    error,
    onEditClick,
    onDeleteClick,
  } = props;

  const style = {
    borderBottom: '4px solid',
    borderColor: isActive ? (error ? 'red' : '#3366FF') : '#595e69',
    color: isActive
      ? error
        ? 'red'
        : '#3366FF'
      : error
      ? '#FF5C5C'
      : '#595e69',
    cursor: 'pointer',
    maxWidth: maxWidth,
    gap: '10px',
  };

  return (
    <Div
      p="12px 32px"
      m="0 0 40px 0"
      {...subMenuStyle}
      style={style}
      onClick={() => onClick(menuItem)}
      d="flex"
      align="center"
    >
      {menuItem}{' '}
      {edit ? (
        <Button
          h="2rem"
          w="1rem"
          bg={error ? 'danger300' : 'info300'}
          hoverBg={error ? 'danger400' : 'info400'}
          rounded="lg"
          onClick={onEditClick}
        >
          <Icon
            name="EditSolid"
            size="15px"
            color={error ? 'danger700' : 'info700'}
          />
        </Button>
      ) : (
        <Text h="2rem" w="1rem"></Text>
      )}
      {isDelete && onDeleteClick ? (
        <Button
          h="2rem"
          w="1rem"
          bg={error ? 'danger300' : 'info300'}
          hoverBg={error ? 'danger400' : 'info400'}
          rounded="lg"
          onClick={onDeleteClick}
        >
          <Icon
            name="Delete"
            size="15px"
            color={error ? 'danger700' : 'info700'}
          />
        </Button>
      ) : (
        <Text h="2rem" w="1rem"></Text>
      )}
    </Div>
  );
}

export function getUserSubMenuLinks(
  currentUser: SessionUser,
  hasCargo?: boolean,
) {
  const userRole = getUserRole(currentUser);

  switch (userRole) {
    case UserRoles.BTS_ADMIN:
      return getAdminSubLinks(hasCargo);
    case UserRoles.BTS_MANAGER_OPERATOR:
      return getManagerOperatorSubLinks(hasCargo);
    // Weighman should have the same view as the operator.
    case UserRoles.BTS_OPERATOR:
      return getOperatorSubLinks();
    case UserRoles.BTS_OPERATOR_WEIGHMAN:
      return getOperatorSubLinks();
    case UserRoles.BTS_WEIGHMAN:
      return getOperatorSubLinks();
    case UserRoles.BTS_OFFICE_MANAGER:
      return getOfficeManagerSubLinks();
    default:
      throw new Error(`Unrecognized Cognito user group role "${userRole}".`);
  }
}

function getAdminSubLinks(hasCargo?: boolean) {
  return []
    .concat(getManagerOperatorSubLinks(hasCargo))
    .concat(getOfficeManagerSubLinks());
}

function getManagerOperatorSubLinks(hasCargo?: boolean) {
  const sublinks = [
    {
      key: 'entryInfo',
      menuItem: 'entryInfo',
    },
  ];

  return sublinks
    .concat(
      hasCargo
        ? {
            key: 'cargoDetails',
            menuItem: 'cargoDetails',
          }
        : {
            key: 'registerCargo',
            menuItem: 'registerCargo',
          },
    )
    .concat(getOperatorSubLinks());
}

function getOperatorSubLinks() {
  return [];
}

function getOfficeManagerSubLinks() {
  return [];
}
