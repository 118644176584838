/* External dependencies */
import { Container, Row, Text } from 'atomize';
import * as Gatsby from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import SubMenu, { getUserSubMenuLinks } from '../topMenu/SubMenu';
import { CargoState } from '../cargo-detail/redux/reducer';
import CargoDetail from '../cargo-detail/CargoDetail';
import CreateCargo from '../create-cargo/CreateCargoForm';
import { getCargo, resetCargoDetails } from '../cargo-detail/redux/actions';
import { isBrowser, isOperatorWeighman } from '../common/helpers';
import { resetCreateCargo } from '../create-cargo/redux/actions';
import { VehicleArrivalPurpose } from '../create-vehicle/vehicles_types';
import BackNav from '../nav/BackNav';
import DangerPopup from '../popup/DangerPopup';
import VehicleDetailsForm from '../vehicle-details/VehicleDetailsForm';
import { PageSpinner } from '../spinner/Spinner';
import { getVehicle, resetVehicleDetails } from './redux/actions';
import { VehicleState } from './redux/reducer';

interface MenuLink {
  key: any;
  menuItem: string;
  Component: typeof CreateCargo | typeof VehicleDetailsForm;
}

export default function VehicleDetails() {
  const [showExitconfirmation, setConfirmationState] = useState(false);
  const flightEntered = formatMessage('flightEntered');

  const dispatch = ReactRedux.useDispatch();

  const { currentUser } = ReactRedux.useSelector(
    (state: RootState) => state.login,
  );
  const cargoState = ReactRedux.useSelector(
    (state: RootState): CargoState => state.cargo,
  );
  const { isFormChanged, loading, vehicle } = ReactRedux.useSelector(
    (state: RootState): VehicleState => state.vehicle,
  );

  const menuItems = getUserSubMenuLinks(currentUser, !!cargoState.cargo);
  const [submenuItem, setSubmenuItem] = useState(
    menuItems.length ? menuItems[0].menuItem : null,
  );
  const id: any = isBrowser && localStorage.getItem('vehicleId');

  useEffect(() => {
    dispatch(getVehicle(id));

    if (!isOperatorWeighman(currentUser)) {
      dispatch(getCargo(id));
    }

    return () => {
      dispatch(resetVehicleDetails());
      dispatch(resetCargoDetails());
      dispatch(resetCreateCargo());
    };
  }, []);

  if (loading || cargoState.loading) {
    return <PageSpinner />;
  }

  const onSubmenuItemClick = (item) => setSubmenuItem(item);

  const onClickBackNav = () => {
    isFormChanged ? setConfirmationState(true) : Gatsby.navigate(-1);
  };

  const page = (() => {
    switch (submenuItem) {
      case 'registerCargo':
        return <CreateCargo setSubMenuItem={setSubmenuItem} />;
      case 'cargoDetails':
        return <CargoDetail />;
      default:
        return <VehicleDetailsForm />;
    }
  })();

  return (
    <Container>
      <Row m={{ y: '1rem' }}>
        <BackNav
          label={`${vehicle?.vehiclePlateNumber} ${flightEntered}`}
          onClick={onClickBackNav}
        />
      </Row>
      {menuItems.length > 0 && (
        <Row
          m={{ y: '1.5rem', x: '1rem' }}
          d="flex"
          w="auto"
          flexWrap="nowrap"
          overflow="auto"
          style={{ whiteSpace: 'nowrap' }}
        >
          {menuItems
            .filter((item) => {
              if (item.menuItem === 'registerCargo') {
                if (vehicle?.arrivalPurpose === VehicleArrivalPurpose.WEIGHIN) {
                  return false;
                }
              }
              return true;
            })
            .map(({ key, menuItem }: MenuLink) => (
              <Row key={key}>
                <SubMenu
                  isActive={menuItem === submenuItem}
                  menuItem={formatMessage(menuItem)}
                  onClick={() => setSubmenuItem(menuItem)}
                />
              </Row>
            ))}
        </Row>
      )}
      <Row>{page}</Row>

      {showExitconfirmation && (
        <DangerPopup
          cancelText={<FormattedMessage id="cancel" />}
          onClose={() => setConfirmationState(false)}
          onSubmit={() => Gatsby.navigate('/vehicles')}
          submitText={<FormattedMessage id="exit" />}
          title={<FormattedMessage id="saveChangesExitTitle" />}
        >
          <Text>{<FormattedMessage id="saveChangesExitDescription" />}</Text>
        </DangerPopup>
      )}
    </Container>
  );
}
