/* External dependencies */
import { Div, Icon, Text } from 'atomize';
import React from 'react';
import FlashIcon from '../../assets/svg/flash';

type NotificationType = 'success' | 'danger';

export interface NotificationProps {
  children?: React.ReactNode;
  title?: string;
  type?: NotificationType;
}

export default function Notification(props: NotificationProps) {
  const { children, title, type } = props;
  return (
    <Div
      d="flex"
      bg={type ? `${type}_color` : 'none'}
      p={{ l: '12px', r: '12px', y: '16px' }}
      rounded="sm"
      w="100%"
    >
      {type === 'danger' ? (
        <Div m="auto 1rem">
          <FlashIcon width={20} height={20} />
        </Div>
      ) : (
        <Icon color="white" name="Checked" size="21px" m="auto 1rem" />
      )}
      <Div>
        <Text tag="h2" textColor="white">
          {title}
        </Text>
        <Text textColor="white">{children}</Text>
      </Div>
    </Div>
  );
}
