/* External dependencies */
import { Button, Col, Div, Row } from 'atomize';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

/* Local dependencies */
import PrintIcon from '../../../svg/PrintIcon';

export interface IconButtonPrintProps {
  children?: any;
}

export default function IconButtonPrint({ children }: IconButtonPrintProps) {
  const componentRef = useRef();
  const widthPrintIcon = 20;
  const heightPrintIcon = 18;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Row>
        <Col size="6">
          <Button onClick={handlePrint} type="button" bg="transparent">
            <PrintIcon
              width={widthPrintIcon}
              height={heightPrintIcon}
              fill="#3366FF"
            />
          </Button>
        </Col>
      </Row>
      <Div d="none">
        <Div ref={componentRef} m={{ x: '15pt', y: '15pt' }}>
          {children}
        </Div>
      </Div>
    </>
  );
}
