/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

/* Local dependencies */
import formatMessage from '../../../intl/formatMessage';
import DateTime from '../../common/DateTime';
import { Consignee } from '../../create-cargo/redux/actions';
import TitleToPrint from '../printComponents/TitleToPrint';
import '../index.scss';

interface ConsigneePassToPrintProps {
  consignee: Consignee;
  addStyles?: boolean;
}

export default function ConsigneePassToPrint({
  consignee,
  addStyles = true,
}: ConsigneePassToPrintProps) {
  const {
    arrivalDate,
    departureCountry,
    name,
    slots,
    trailerPlateNumber,
    vehiclePlateNumber,
  } = consignee;

  const weight = consignee.cargoWeight;

  const rowArrayComponent = [
    {
      label: formatMessage('arrivalDate'),
      value: <DateTime date={arrivalDate} />,
    },
    {
      label: formatMessage('vehiclePlateNumber'),
      value: vehiclePlateNumber,
    },
    {
      label: formatMessage('trailerPlateNumber'),
      value: trailerPlateNumber || '',
    },
  ];

  if (departureCountry) {
    rowArrayComponent.push({
      label: formatMessage('departureCountry'),
      value: departureCountry,
    });
  }

  rowArrayComponent.push(
    {
      label: formatMessage('organization'),
      value: name,
    },
    {
      label: formatMessage('cargoWeight'),
      value: `${weight}`,
    },
    {
      label: formatMessage('quantitySlots'),
      value: `${slots}`,
    },
  );

  function getPageSize() {
    return `@page {  size: 10cm 10cm; }`;
  }

  return (
    <Div className="permit_print">
      {addStyles && <style>{getPageSize()}</style>}
      <TitleToPrint
        title={formatMessage('cargoPass')}
        className="permit-title_print"
      />
      {rowArrayComponent.map((item, index) => (
        <Row key={index} className="permit-row_print">
          <Col size="6">
            <Text>{item.label}</Text>
          </Col>
          <Col size="6">
            <Text className="permit-text_print--bold">{item.value}</Text>
          </Col>
        </Row>
      ))}
      <Row className="stamp-permit-row_print">
        <Col>
          <Text className="stamp-permit-text_print">
            <FormattedMessage id="placeForStamp" />
          </Text>
        </Col>
      </Row>
    </Div>
  );
}
